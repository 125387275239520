<template>
다폴더 배당 보너스
</template>

<script>
export default {
  name:"EventMultiFolder"
}
</script>

<style scoped>

</style>
